import { Button, Toolbar, useMediaQuery } from "@mui/material";
import { useRouter } from "next/router";
import React, { Fragment } from "react";
import {
  AppBarStyled,
  ButtonContainer,
  GridContent,
  GridStyled,
  ImageStyled,
  LinkStyled,
  Logo,
  SubtitleStyled,
  TitleStyled,
} from "../components/error404/styles";
import * as config from "../config/config";
import { localisation } from "../helpers/constant";
import en from "../locales/en";
import ms from "../locales/ms";

export default function Custom404() {
  const isAboveDesktop = useMediaQuery("(min-width:1440px)");
  const isAboveTablet = useMediaQuery("(min-width:1280px)");
  const isAboveMobile = useMediaQuery("(min-width:768px)");

  const { locale } = useRouter();

  const copies = locale === localisation.ENGLISH ? en : ms;

  return (
    <Fragment>
      <AppBarStyled position="static">
        <Toolbar>
          <LinkStyled href="/">
            <GridStyled>
              <Logo
                src={config.assetDomain + "/images/logo/naikgaji-logo.png"}
                alt="hiredly-logo"
                height={isAboveMobile ? 70 : 50}
                width={isAboveMobile ? 70 : 50}
              />
            </GridStyled>
          </LinkStyled>
        </Toolbar>
      </AppBarStyled>
      <GridContent>
        <ImageStyled
          src={config.assetDomain + "/images/empty-state/404.svg"}
          style={{
            maxHeight: isAboveDesktop
              ? 300
              : isAboveTablet
              ? 200
              : isAboveMobile
              ? 200
              : "auto",
            maxWidth: isAboveDesktop
              ? 300
              : isAboveTablet
              ? 200
              : isAboveMobile
              ? 200
              : "auto",
          }}
        />
        <LinkStyled href={"https://storyset.com/web"}>
          <SubtitleStyled
            sx={{
              fontSize: isAboveMobile ? "10px" : "6px",
              position: "absolute",
              bottom: 10,
              right: isAboveMobile ? 10 : 0,
              left: isAboveMobile ? undefined : 0,
            }}
          >
            Web illustrations by Storyset
          </SubtitleStyled>
        </LinkStyled>
        <TitleStyled
          variant={isAboveTablet ? "h3" : isAboveMobile ? "h4" : "h6"}
        >
          {copies.somethingWentWrong}
        </TitleStyled>
        <SubtitleStyled
          variant={isAboveTablet ? "h5" : isAboveMobile ? "h6" : "body1"}
          marginBottom={"20px"}
        >
          {copies.somethingWentWrongSubtitle}
        </SubtitleStyled>
        <ButtonContainer>
          <LinkStyled href="/">
            <Button
              text_transform={"capitalize"}
              variant={"contained"}
              sx={{
                borderRadius: "8px",
                minHeight: isAboveMobile ? "50px" : "40px",
                textTransform: "capitalize",
                fontSize: isAboveTablet
                  ? "20px"
                  : isAboveMobile
                  ? "18px"
                  : "12px",
              }}
            >
              {copies.home}
            </Button>
          </LinkStyled>
        </ButtonContainer>
      </GridContent>
    </Fragment>
  );
}
